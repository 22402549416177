import { observable, reaction, decorate } from 'mobx';
import SvgText from 'svg-text';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Data {
  lastModified = 0;
  sidebarVisible = false;
  dataLoaded = false;
  error = false;
  activeStep = 1;
  texts = '';
  exporting = false;
  filteringActive = false;
  activeFilters = 0;
  activeElem = null;

  notice = {
    display: false,
    title: '',
    description: '',
  };

  initialData = {
    trends: [],
    effects: [],
    sectors: [],
    impacts: [],
    consequences: [],
    items: [],
  };

  filteredData = {
    trends: [],
    effects: [],
    sectors: [],
    impacts: [],
    consequences: [],
    items: [],
  };

  searchParams = {
    trends: [],
    effects: [],
    sectors: [],
    impacts: [],
    consequences: [],
  };

  svgTexts = [];

  dimensions = {
    x: window.innerWidth - 300,
    y: window.innerHeight,
  };

  api = null;
  version = '0.1';
  cookiesAllowed = null;

  init(api) {
    this.api = api;

    this.cookiesAllowed =
      cookies.get('cookies_allowed') &&
      cookies.get('cookies_allowed') === 'true';
    if (this.cookiesAllowed) {
      this.enableTracking();
    }

    //first, get last modified date from server
    this.api.getLastModified().then((data) => {
      if (data !== undefined) {
        this.lastModified = data;
      }
    });

    //react as soon as we've got a last modified value
    reaction(
      () => [this.lastModified],
      () => {
        if (this.lastModified === 'error') {
          if (localStorage.getItem('data') && localStorage.getItem('texts')) {
            this.mapData(JSON.parse(localStorage.getItem('data')));
            this.mapTexts(JSON.parse(localStorage.getItem('texts')));

            alert(
              'Er is een probleem met het binnen halen van nieuwe data. Data van een eerder bezoek wordt nu getoond, dit is mogelijk verouderde data. Onze excuses voor het ongemak.'
            );
          } else {
            alert(
              'Er is een probleem met het binnen halen van de data. Onze excuses voor het ongemak.'
            );
          }
        } else if (
          //if last modified is not equal to saved modified date on device,
          //this means that there is new data so
          //store new modified date & get data
          //else if data is on device, use stored data
          this.lastModified.toString() !== localStorage.getItem('lastModified')
        ) {
          localStorage.setItem('lastModified', this.lastModified);
          this.getAppData();
        } else {
          if (localStorage.getItem('data') && localStorage.getItem('texts')) {
            this.mapData(JSON.parse(localStorage.getItem('data')));
            this.mapTexts(JSON.parse(localStorage.getItem('texts')));
          } else {
            this.getAppData();
          }
        }
      }
    );

    reaction(
      () => [this.cookiesAllowed],
      () => {
        if (this.cookiesAllowed) {
          cookies.set('cookies_allowed', 'true', { path: '/' });
        } else {
          cookies.set('cookies_allowed', 'false', { path: '/' });
        }
      }
    );

    reaction(
      () => [this.sidebarVisible],
      () => {
        this.updateDimensions();
      }
    );

    //log errors
    reaction(
      () => [this.error],
      () => {
        if (this.error) {
          console.log('ERROR');
        }
      }
    );
  }

  enableTracking() {
    ReactGA.initialize(process.env.REACT_APP_UA, {
      debug: false,
    });
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.trackEvent({
      category: 'Navigeren',
      action: 'Volgende stap aangeklikt',
      label: 'Stap 1',
    });
  }

  trackEvent(event) {
    if (typeof this.cookiesAllowed === 'undefined') {
      this.cookiesAllowed = true;
      this.enableTracking();
      ReactGA.event(event);
    } else if (this.cookiesAllowed) {
      ReactGA.event(event);
    }
  }

  trackPageview(event) {
    if (this.cookiesAllowed) {
      if (event.modal) {
        ReactGA.modalview(event.url);
      } else {
        ReactGA.pageview(event.url);
      }
    }
  }

  updateDimensions() {
    const delta = this.sidebarVisible ? 250 : 0;
    this.dimensions = {
      x: window.innerWidth - delta,
      y: window.innerHeight,
    };
  }

  getAppData() {
    //get data from server and store on device
    this.api.getAppTexts().then((data) => {
      localStorage.setItem('texts', JSON.stringify(data));
      this.mapTexts(data);
    });
    this.api.getAppData().then((data) => {
      localStorage.setItem('data', JSON.stringify(data));
      this.mapData(data);
    });
  }

  mapData(data) {
    //map data to correct variables
    this.initialData.trends = data.trends;
    this.initialData.effects = data.effects;
    this.initialData.sectors = data.sectors;
    this.initialData.impacts = data.impacts;
    this.initialData.consequences = data.consequences;
    this.initialData.items = data.items;
    this.filteredData = this.initialData;

    this.setupSVGTexts();
  }

  setupSVGTexts() {
    this.initialData.effects.forEach((effect) => {
      this.svgTexts.push({
        id: effect.id,
        data: new SvgText({
          text: effect.title.trim(),
          element: document.querySelector('#texts'),
          maxWidth: 77,
          maxHeight: 77,
          textOverflow: 'ellipsis',
          align: 'center',
          verticalAlign: 'middle',
          attrs: {
            fontSize: 9,
            lineHeight: 13,
            fontFamily:
              "'Arial', 'Open Sans Condensed', 'Open Sans', sans-serif",
            fill: '#38515e',
          },
        }),
      });
    });
    this.initialData.items.forEach((item) => {
      this.svgTexts.push({
        id: item.id,
        data: new SvgText({
          text: item.title.trim(),
          element: document.querySelector('#texts'),
          maxWidth: 68,
          maxHeight: 68,
          textOverflow: 'ellipsis',
          align: 'center',
          verticalAlign: 'middle',
          attrs: {
            fontFamily:
              "'Arial', 'Open Sans Condensed', 'Open Sans', sans-serif",
            fontSize: 7,
            lineHeight: 12,
            fill: '#333333',
          },
          maxLines: 8,
        }),
      });
    });
    this.dataLoaded = true;
  }

  mapTexts(data) {
    this.texts = data;
    this.sidebarVisible = true;
  }

  updateSearchParams(category, id) {
    //store or remove active search ids
    const item = this.initialData[category].find((obj) => {
      return obj.id === id;
    });
    const index = this.searchParams[category].indexOf(id);
    if (index > -1) {
      this.searchParams[category].splice(index, 1);
      this.activeFilters--;
      this.trackEvent({
        category: 'Filteren',
        action: 'Filter uitgezet',
        label: item.title,
      });
    } else {
      this.searchParams[category].push(id);
      this.activeFilters++;
      this.trackEvent({
        category: 'Filteren',
        action: 'Filter aangezet',
        label: item.title,
      });
    }
    this.mapFilteredData();
  }

  setSearchParams(category, id) {
    //store or remove active search ids
    const index = this.searchParams[category].indexOf(id);
    if (this.searchParams[category].length === 0) {
      this.activeFilters++;
    }
    if (index > -1) {
      this.searchParams[category] = [];
      this.activeFilters--;
    } else {
      this.searchParams[category] = [id];
    }
    this.mapFilteredData();
  }

  mapFilteredData() {
    if (this.activeFilters === 0) {
      this.filteredData = this.initialData;
    } else {
      //compare searchparams with items
      let items = this.initialData.items;
      if (this.searchParams.trends.length > 0) {
        items = items.filter((item, index) => {
          return this.searchParams.trends.some((e) => item.trends.includes(e));
        });
      }
      if (this.searchParams.sectors.length > 0) {
        items = items.filter((item, index) => {
          return this.searchParams.sectors.some((e) =>
            item.sectors.includes(e)
          );
        });
      }
      if (this.searchParams.impacts.length > 0) {
        items = items.filter((item, index) => {
          return this.searchParams.impacts.some((e) =>
            item.impacts.includes(e)
          );
        });
      }
      if (this.searchParams.consequences.length > 0) {
        items = items.filter((item, index) => {
          return this.searchParams.consequences.some((e) =>
            item.consequences.includes(e)
          );
        });
      }
      this.filteredData = {
        trends: this.mapFinalItems(items, 'trends'),
        effects: this.mapFinalItems(items, 'effects'),
        items: items,
      };
    }
  }

  mapFinalItems(items, type) {
    let output = items.map((item) => {
      if (typeof item[type] === 'object') {
        let fOutput = [];
        item[type].forEach((a) => {
          fOutput.push(a);
        });
        return fOutput;
      } else {
        return item[type];
      }
    });
    output = [].concat.apply([], output);
    output = [...new Set(output)];
    output = output.map((item) => {
      if (type === 'trends' && this.searchParams[type].length > 0) {
        if (this.searchParams[type].includes(item)) {
          return this.getItemByID(item, type);
        } else {
          return null;
        }
      } else if (type === 'effect') {
        return this.getItemByID(item, type);
      } else {
        return this.getItemByID(item, type);
      }
    });
    output = output.filter(Boolean);
    return [].concat.apply([], output);
  }

  getItemByID(item, type) {
    const found = this.initialData[type].filter((i) => {
      return i.id === item;
    });
    return found;
  }
}
decorate(Data, {
  lastModified: observable,
  sidebarVisible: observable,
  dataLoaded: observable,
  error: observable,
  activeStep: observable,
  texts: observable,
  exporting: observable,
  filteringActive: observable,
  activeFilters: observable,
  activeElem: observable,
  notice: observable,
  initialData: observable,
  filteredData: observable,
  searchParams: observable,
  dimensions: observable,
  svgTexts: observable,
  cookiesAllowed: observable,
});
const data = new Data();
export default data;
