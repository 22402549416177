import axios from 'axios';

class Api {
  getURL() {
    return (
      process.env.REACT_APP_API_URL ||
      `${document.location.origin}/wp/data/bzk/v1`
    );
  }

  getLastModified() {
    return axios({
      method: 'get',
      url: '/modified',
      baseURL: this.getURL(),
      responseType: 'json',
      validateStatus: function (status) {
        return status >= 200 && status < 300; // default
      },
    })
      .then((response) => {
        return response.data.last_modified;
      })
      .catch((error) => {
        this.errorLog(error);
        return 'error';
      });
  }

  getAppData() {
    return axios({
      method: 'get',
      url: '/all',
      baseURL: this.getURL(),
      responseType: 'json',
      validateStatus: function (status) {
        return status >= 200 && status < 300; // default
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorLog(error);
      });
  }

  getAppTexts() {
    return axios({
      method: 'get',
      url: '/texts',
      baseURL: this.getURL(),
      responseType: 'json',
      validateStatus: function (status) {
        return status >= 200 && status < 300; // default
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.errorLog(error);
      });
  }

  errorLog(error) {
    console.log('Something went wrong');
    console.log('====================');
    console.log(error);
  }
}

const api = new Api();
export default api;
