export const iconCenters = [
	[{ x: -7.5, y: -54 }],
	[{ x: 40, y: -7.5 }, { x: -55, y: -7.5 }],
	[{ x: 34, y: -32 }, { x: -7.5, y: 40 }, { x: -49, y: -30 }],
	[
		{ x: 30, y: -37 },
		{ x: 26, y: 28 },
		{ x: -43, y: 24 },
		{ x: -43, y: -38 }
	],
	[
		{ x: 23, y: -45 },
		{ x: 39, y: 10 },
		{ x: -7, y: 41 },
		{ x: -51, y: 9 },
		{ x: -37, y: -44 }
	],
	[
		{ x: 19, y: -47 },
		{ x: 41, y: -6 },
		{ x: 18, y: 34 },
		{ x: -30, y: 35 },
		{ x: -54, y: -8 },
		{ x: -33, y: -47 }
	],
	[
		{ x: 16, y: -49 },
		{ x: 40, y: -15 },
		{ x: 31, y: 23 },
		{ x: -7, y: 41 },
		{ x: -43, y: 23 },
		{ x: -53, y: -17 },
		{ x: -29, y: -49 }
	],
	[
		{ x: 13, y: -50 },
		{ x: 37, y: -23 },
		{ x: 37, y: 12 },
		{ x: 12, y: 37 },
		{ x: -25, y: 38 },
		{ x: -50, y: 12 },
		{ x: -51, y: -25 },
		{ x: -26, y: -51 }
	],
	[
		{ x: 11, y: -51 },
		{ x: 35, y: -29 },
		{ x: 40, y: 2 },
		{ x: 24, y: 30 },
		{ x: -7, y: 41 },
		{ x: -37, y: 30 },
		{ x: -54, y: 2 },
		{ x: -49, y: -31 },
		{ x: -24, y: -51 }
	]
];
