import React, { Component } from 'react';
import { decorate } from 'mobx';
import { observer, inject } from 'mobx-react';

class Pages extends Component {
  constructor(props) {
    super(props);
    this.cookieRef = React.createRef();
    this.policyRef = React.createRef();
    this.a11yRef = React.createRef();
  }
  closeModal() {
    this.props.store.notice = {
      display: false,
      title: '',
      description: '',
      html: '',
      type: '',
    };
    this.props.store.activeElem = null;
  }
  render() {
    const texts = this.props.store.texts;
    const cookies_not_set =
      texts.cookies_privacy && texts.cookies_privacy.cookies_not_set
        ? texts.cookies_privacy.cookies_not_set
        : '<p>Op dit moment heb je nog geen keuze gemaakt</p>';
    const cookies_accepted =
      texts.cookies_privacy && texts.cookies_privacy.cookies_accepted
        ? texts.cookies_privacy.cookies_accepted
        : '<p>Op dit moment heb je cookies geaccepteerd</p>';
    const cookies_declined =
      texts.cookies_privacy && texts.cookies_privacy.cookies_declined
        ? texts.cookies_privacy.cookies_declined
        : '<p>Op dit moment heb je cookies geweigerd</p>';

    const cookieSetting =
      typeof this.props.store.cookiesAllowed === 'undefined'
        ? cookies_not_set
        : this.props.store.cookiesAllowed === false
        ? cookies_declined
        : cookies_accepted;
    return (
      <div className='bzk-pages'>
        <a
          href='https://ruimtelijkeadaptatie.nl/nas/adaptatietool/'
          target='_blank'
          className='bzk-pages__logo-link'
          rel='noopener noreferrer'
        >
          <img
            src={`${process.env.PUBLIC_URL}/android-chrome-192x192.png`}
            className='bzk-pages__logo'
            alt='NAS adaptatietool voor de analyse van klimaatrisico’s'
          />
        </a>
        <div className='bzk-pages__button-wrapper'>
          <button
            ref={this.cookieRef}
            className='bzk-pages__button'
            onClick={() => {
              if (
                !this.props.store.notice.display ||
                this.props.store.notice.type !== 'cookies'
              ) {
                this.props.store.activeElem = this.cookieRef;
                this.props.store.trackPageview({
                  modal: true,
                  url: '/cookie-instellingen',
                });
                this.props.store.notice = {
                  display: true,
                  title:
                    texts.cookies_privacy && texts.cookies_privacy.cookies_title
                      ? texts.cookies_privacy.cookies_title
                      : 'Voorkeuren wijzigen',
                  type: 'cookies',
                  html: cookieSetting,
                };
              } else {
                this.closeModal();
              }
            }}
          >
            Cookie instellingen
          </button>
          {texts.a11y && texts.a11y.a11y_title && texts.a11y.a11y_content && (
            <button
              ref={this.a11yRef}
              className='bzk-pages__button'
              onClick={() => {
                if (
                  !this.props.store.notice.display ||
                  this.props.store.notice.type !== 'a11y'
                ) {
                  this.props.store.activeElem = this.a11yRef;
                  this.props.store.trackPageview({
                    modal: true,
                    url: '/toegankelijkheid',
                  });
                  this.props.store.notice = {
                    display: true,
                    type: 'a11y',
                    title:
                      texts.a11y && texts.a11y.a11y_title
                        ? texts.a11y.a11y_title
                        : 'Toegankelijkheid',
                    html:
                      texts.a11y && texts.a11y.a11y_content
                        ? texts.a11y.a11y_content
                        : '',
                  };
                } else {
                  this.closeModal();
                }
              }}
            >
              Toegankelijkheid
            </button>
          )}
          {texts.cookies_privacy &&
            texts.cookies_privacy.privacy_policy_title &&
            texts.cookies_privacy.privacy_policy_content && (
              <button
                ref={this.policyRef}
                className='bzk-pages__button'
                onClick={() => {
                  if (
                    !this.props.store.notice.display ||
                    this.props.store.notice.type !== 'privacy'
                  ) {
                    this.props.store.activeElem = this.policyRef;
                    this.props.store.trackPageview({
                      modal: true,
                      url: '/privacy-policy',
                    });
                    this.props.store.notice = {
                      display: true,
                      type: 'privacy',
                      title:
                        texts.cookies_privacy &&
                        texts.cookies_privacy.privacy_policy_title
                          ? texts.cookies_privacy.privacy_policy_title
                          : 'Privacy policy',
                      html:
                        texts.cookies_privacy &&
                        texts.cookies_privacy.privacy_policy_content
                          ? texts.cookies_privacy.privacy_policy_content
                          : '',
                    };
                  } else {
                    this.closeModal();
                  }
                }}
              >
                Privacy policy
              </button>
            )}
        </div>
      </div>
    );
  }
}
decorate(Pages, {});
export default inject('store')(observer(Pages));
