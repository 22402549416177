import React, { Component } from 'react';
import * as d3 from 'd3';
import { observer, inject } from 'mobx-react';
import { observable, decorate } from 'mobx';
import { iconCenters } from '../data/variables';
import SvgText from 'svg-text';
import Pages from './pages';
import Modals from './modals';

class Canvas extends Component {
  simulation = '';
  dragSimulation = '';
  Consequences = [];
  Impacts = [];
  svg = '';
  canvas = '';
  fontFamily = "'Arial', 'Open Sans Condensed', 'Open Sans', sans-serif";
  nodes = [];
  zoom = false;
  Links = [];
  Items = [];
  dTrends = '';
  dEffects = '';
  dLinks = '';
  dItems = '';
  Trends = [];
  Effects = [];
  indexTicked = 0;
  sidebarAdded = false;
  selectedNodes = [];
  selectedLinks = [];
  trendPositions = [];
  effectPositions = [];
  filtersInArchive = 0;

  zoomLevel = {
    current: 1,
    desktop: {
      min: 0.25,
      max: 3,
    },
    mobile: {
      min: 0.1,
      max: 2,
    },
  };

  zoomInDisabled = false;
  zoomOutDisabled = false;
  allowedToRemove = {
    allowed: false,
  };

  componentDidMount() {
    this.setupVariables();
    this.setContext();

    window.addEventListener(
      'keydown',
      (e) => {
        if (this.props.store.activeFilters !== 0) {
          if (e.key === '-' || e.keyCode === 189) {
            e.preventDefault();
            this.zoomCanvas(false);
          }
          if (e.key === '+' || e.keyCode === 187) {
            e.preventDefault();
            this.zoomCanvas(true);
          }
          if (e.key === 'ArrowLeft' || e.keyCode === 37) {
            e.preventDefault();
            this.moveCanvas('right');
          }
          if (e.key === 'ArrowUp' || e.keyCode === 38) {
            e.preventDefault();
            this.moveCanvas('down');
          }
          if (e.key === 'ArrowRight' || e.keyCode === 39) {
            e.preventDefault();
            this.moveCanvas('left');
          }
          if (e.key === 'ArrowDown' || e.keyCode === 40) {
            e.preventDefault();
            this.moveCanvas('up');
          }
        }
        if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
          if (
            this.props.store.activeElem &&
            this.props.store.activeElem.current
          ) {
            this.props.store.activeElem.current.focus();
            this.props.store.activeElem = null;
          }
          if (this.props.store.notice.display) {
            e.preventDefault();
            this.props.store.notice = {
              display: false,
              title: '',
              description: '',
              html: '',
              type: '',
            };
          }

          if (this.allowedToRemove.allowed) {
            e.preventDefault();
            this.allowedToRemove.allowed = false;
          }
        }
      },
      true
    );
  }

  componentDidUpdate() {
    if (
      this.props.store.activeFilters > 0 &&
      this.props.store.activeStep === 1 &&
      this.filtersInArchive !== this.props.store.activeFilters
    ) {
      const box = document.getElementById('canvas');
      const transform = box.getAttribute('transform');
      box.removeAttribute('transform');
      this.setupVariables();
      this.setupItemLinks();
      this.dLinks = this.drawLinks(this.Links);
      this.dTrends = this.drawTrends(this.Trends);
      this.dEffects = this.drawEffects(this.Effects);
      this.dItems = this.drawItems(this.Items);
      this.nodes = this.Trends.concat(this.Effects.concat(this.Items));
      this.simulation
        .nodes(this.nodes)
        .force(
          'collide',
          d3
            .forceCollide()
            .radius((d) => {
              if (d.id.includes('trend')) {
                return 70;
              } else if (d.id.includes('effect')) {
                return 50;
              } else {
                return 70;
              }
            })
            .iterations(25)
        )
        .force(
          'link',
          d3
            .forceLink(this.Links)
            .id(function (d) {
              return d.id;
            })
            .distance(5)
            .strength(0.8)
        );
      if (this.props.store.activeFilters > 0) {
        this.simulation.alphaDecay(0.15);
      } else {
        this.simulation.alphaDecay(1);
      }
      this.simulation.alpha(1).restart();
      if (transform) {
        box.setAttribute('transform', transform);
      }
    }
    if (this.props.store.activeStep === 3) {
      if (!this.sidebarAdded) {
        this.addSidebar();
        this.sidebarAdded = true;
      }
    }
    this.filtersInArchive = this.props.store.activeFilters;
  }

  setupVariables = () => {
    this.Trends = this.props.store.filteredData.trends;
    this.Effects = this.props.store.filteredData.effects;
    this.Sectors = this.props.store.initialData.sectors;
    this.Items = this.props.store.filteredData.items;
    this.Consequences = this.props.store.initialData.consequences;
    this.Impacts = this.props.store.initialData.impacts;
  };

  setContext = () => {
    //initial svg setup (with zooming)
    this.w =
      this.props.store.sidebarVisible && window.innerWidth > 768
        ? window.innerWidth - 250
        : window.innerWidth;
    this.h = window.innerHeight;
    const minZoom =
      window.innerWidth > 768
        ? this.zoomLevel.desktop.min
        : this.zoomLevel.mobile.min;
    const maxZoom =
      window.innerWidth > 768
        ? this.zoomLevel.desktop.max
        : this.zoomLevel.mobile.max;
    this.zoom = d3
      .zoom()
      .scaleExtent([minZoom, maxZoom])
      .on('zoom', this.zoomed);

    this.svg = d3
      .select('svg')
      .attr('height', '100%')
      .attr('width', '100%')
      .attr('class', 'svg-content')
      .attr('viewBox', `0 0 ${this.w} ${this.h}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('pointer-events', 'all')
      .call(this.zoom);

    this.canvas = this.svg.append('g').attr('id', 'canvas');

    this.addMeta();
    this.nodes = this.Trends.concat(this.Effects.concat(this.Items));
    this.simulation = d3
      .forceSimulation(this.nodes)
      .on('tick', this.ticked)
      .on('end', () => {
        setTimeout(() => {
          this.zoomFit(false);
        }, 10);
        this.props.store.filteringActive = false;
      });

    this.dragSimulation = d3
      .forceSimulation(this.nodes)
      .force('collide', d3.forceCollide().radius(65).iterations(15))
      .on('tick', this.ticked);
  };

  addMeta = () => {
    this.canvas
      .append('title')
      .text('NAS adaptatietool voor de analyse van klimaatrisico’s');
    this.canvas
      .append('desc')
      .text(
        'Maak in drie stappen uw eigen visualisatie van klimaatadaptatie. Kies in het linkermenu welke klimaattrend(s) en/of sectoren voor u van belang zijn. U kunt ook filteren op de impact en de aard van de mogelijke klimaatgevolgen.'
      );
  };

  zoomed = () => {
    this.zoomLevel.current = d3.event.transform.k;
    // d3.event.transform = this.zoomLevel.transform;
    if (this.props.store.activeStep <= 3) {
      this.zoomInDisabled = d3.event.transform.k === this.zoomLevel.desktop.max;
      this.zoomOutDisabled =
        d3.event.transform.k === this.zoomLevel.desktop.min;
      this.canvas.attr('transform', d3.event.transform);
    }
  };

  moveCanvas = (direction) => {
    const currentTransform = d3.zoomTransform(this.svg.node());
    const factor = 200 * currentTransform.k;
    const pos = {
      x: currentTransform.x,
      y: currentTransform.y,
    };
    if (direction === 'up') {
      pos.y = pos.y - factor;
    }
    if (direction === 'down') {
      pos.y = pos.y + factor;
    }
    if (direction === 'left') {
      pos.x = pos.x - factor;
    }
    if (direction === 'right') {
      pos.x = pos.x + factor;
    }

    const transform = d3.zoomIdentity
      .translate(pos.x, pos.y)
      .scale(currentTransform.k);

    this.svg
      .transition()
      .duration(250) // milliseconds
      .call(this.zoom.transform, transform);
  };

  zoomCanvas = (zoom_in) => {
    const currentTransform = d3.zoomTransform(this.svg.node());
    const factor = zoom_in ? 1.5 : 1 / 1.5;
    const newZoom =
      currentTransform.k * factor > this.zoomLevel.desktop.max
        ? this.zoomLevel.desktop.max
        : currentTransform.k * factor < this.zoomLevel.desktop.min
        ? this.zoomLevel.desktop.min
        : currentTransform.k * factor;

    const transform = d3.zoomIdentity
      .translate(currentTransform.x * factor, currentTransform.y * factor)
      .scale(newZoom);

    this.svg
      .transition()
      .duration(250) // milliseconds
      .call(this.zoom.transform, transform);
  };

  setupItemLinks = () => {
    const indexes = [];
    this.Links = [];
    this.Items.forEach((item, index) => {
      if (item.trends.length > 0) {
        const effects = item.effects;
        const trends =
          this.props.store.searchParams.trends.length > 0
            ? item.trends.filter((t) => {
                return this.props.store.searchParams.trends.includes(t);
              })
            : item.trends;
        if (effects.length > 0) {
          trends.forEach((trend) => {
            effects.forEach((effect) => {
              if (!indexes.includes(`line_${effect}_${trend}`)) {
                this.Links.push({
                  source: effect,
                  target: trend,
                  stroke: '#829299',
                  distance: 30,
                  marker: true,
                  id: `line_${effect}_${trend}`,
                });
                indexes.push(`line_${effect}_${trend}`);
              }
              if (!indexes.includes(`line_${item.id}_${effect}`)) {
                this.Links.push({
                  source: item.id,
                  target: effect,
                  strokeDashArray: '0, 8',
                  distance: 45,
                  marker: false,
                  id: `line_${item.id}_${effect}`,
                });
                indexes.push(`line_${item.id}_${effect}`);
              }
            });
          });
        } else {
          trends.forEach((trend) => {
            if (!indexes.includes(`line_${item.id}_${trend}`)) {
              this.Links.push({
                source: item.id,
                target: trend,
                strokeDashArray: '0, 8',
                distance: 45,
                marker: false,
                id: `line_${item.id}_${trend}`,
              });
              indexes.push(`line_${item.id}_${trend}`);
            }
          });
        }
      }
    });
  };

  drawTrends = (trends) => {
    const angle = (2 * Math.PI) / trends.length;
    let trend = this.canvas
      .selectAll('g.trend')
      .data(trends, (d, i) => {
        if (trends.length === 1) {
          d.x = this.w / 2;
          d.y = this.h / 2;
        } else {
          d.x = (Math.cos(angle * i - 1) * this.w) / 2 + this.w / 2;
          d.y = (Math.sin(angle * i - 1) * this.h) / 2 + this.h / 2;
        }
        this.trendPositions[d.id] = {
          dx: d.x,
          dy: d.y,
        };
        return d.id;
      })
      .attr('id', (d) => {
        return d.id;
      })
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem.select('text').text(d.title).call(this.wrap, 90, 'trend');
        this.addIcon(elem.select('.icon').node(), d.svg);
      });

    trend
      .enter()
      .append('g')
      .attr('class', 'node trend')
      .attr('id', (d) => {
        return d.id;
      })
      .on('click', (data, index, nodes) => {
        d3.event.stopPropagation();
      })
      .on('mouseenter', this.nodeMouseEnter)
      .on('mouseleave', this.nodeMouseLeave)
      .call(
        d3
          .drag()
          .on('start', this.dragstarted)
          .on('drag', this.dragged)
          .on('end', this.dragended)
      )
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem
          .append('circle')
          .attr('r', 60)
          .attr('fill', '#EDEDED')
          .attr('stroke-width', 3)
          .attr('stroke', '#C5C5C5');
        elem
          .append('text')
          .text(d.title)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('font-size', '14px')
          .attr('fill', '#38515e')
          .attr('y', '20')
          .attr('font-family', this.fontFamily)
          .style('font-family', this.fontFamily)
          .call(this.wrap, 90, 'trend');

        const icon = elem
          .append('g')
          .attr('class', 'icon')
          .attr('fill', '#324951')
          .attr('fill-rule', 'evenodd')
          .attr('transform', 'translate(-25 -45)');
        this.addIcon(icon.node(), d.svg);
      });

    trend.exit().remove();

    return this.canvas.selectAll('g.trend');
  };

  drawEffects = (effects) => {
    // const angle = 2 * Math.PI / effects.length;
    let effect = this.canvas
      .selectAll('g.effect')
      .data(effects, (d, i) => {
        let x = 0;
        let y = 0;
        if (d.trends.length > 1) {
          x = this.w / 2;
          y = this.h / 2;
        } else {
          const connectedTrends =
            d.trends.length > 0
              ? this.trendPositions[d.trends[0]]
              : { dx: this.w / 2, dy: this.h / 2 };
          x = connectedTrends.dx ? connectedTrends.dx : this.w / 2;
          y = connectedTrends.dy ? connectedTrends.dy : this.h / 2;
        }
        this.effectPositions[d.id] = {
          dx: x,
          dy: y,
        };
        d.x = x;
        d.y = y;
        return d;
      })
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);

        elem.select('text').remove();

        const text = this.props.store.svgTexts.find((t) => {
          return t.id === d.id;
        });
        elem.node().appendChild(text.data.text);
      });

    effect
      .enter()
      .append('g')
      .attr('class', 'node effect')
      .on('click', (data, index, nodes) => {
        d3.event.stopPropagation();
        this.removeNode(data, nodes[index]);
      })
      .on('mouseenter', this.nodeMouseEnter)
      .on('mouseleave', this.nodeMouseLeave)
      .call(
        d3
          .drag()
          .on('start', this.dragstarted)
          .on('drag', this.dragged)
          .on('end', this.dragended)
      )
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem
          .append('circle')
          .attr('class', 'inner-effect')
          .attr('r', 44)
          .attr('fill', '#FFF')
          .attr('stroke-width', 2)
          .attr('stroke', '#C6C6C6');

        const text = this.props.store.svgTexts.find((t) => {
          return t.id === d.id;
        });
        elem.node().appendChild(text.data.text);
      });

    effect.exit().remove();

    effect.order();

    return this.canvas.selectAll('g.effect');
  };

  drawItems = (items) => {
    const arc = d3.arc().outerRadius(58).innerRadius(38);
    const centers = iconCenters;
    let item = this.canvas
      .selectAll('g.item')
      .data(items, (d, i) => {
        let x = 0;
        let y = 0;
        if (d.effects.length > 1) {
          x = this.w / 2;
          y = this.h / 2;
        } else {
          const connectedEffect =
            d.effects.length > 0
              ? this.effectPositions[d.effects[0]]
              : this.trendPositions[d.trends[0]];
          x = connectedEffect.dx ? connectedEffect.dx : 0;
          y = connectedEffect.dy ? connectedEffect.dy : 0;
        }
        d.x = x;
        d.y = y;
        return d;
      })
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem
          .select('circle.impact')
          .attr('stroke', (d) => {
            const i = d.impacts;
            const impact = this.Impacts.find((a) => {
              return i.includes(a.id);
            });
            return impact && impact.color ? impact.color : '#FFF';
          })
          .attr('stroke-width', (d) => {
            return '5px';
          })
          .attr('stroke-dasharray', (d) => {
            const i = d.impacts;
            const impact = this.Impacts.find((a) => {
              return i.includes(a.id);
            });
            return impact && impact.style === 'dashed' ? '7px' : '0px';
          });

        elem
          .select('circle.consequence')
          .attr('fill', (d) => {
            const c = d.consequences;
            if (c.length > 0) {
              const consequence = this.Consequences.find((a) => {
                return c.includes(a.id);
              });
              return consequence.color ? consequence.color : '#FFF';
            }
            return '#FFF';
          })
          .attr('stroke', (d) => {
            const c = d.consequences;
            if (c.length > 0) {
              const consequence = this.Consequences.find((a) => {
                return c.includes(a.id);
              });
              return consequence.color
                ? '#' +
                    consequence.color
                      .replace(/^#/, '')
                      .replace(/../g, (color) =>
                        (
                          '0' +
                          Math.min(
                            255,
                            Math.max(0, parseInt(color, 16) - 80)
                          ).toString(16)
                        ).substr(-2)
                      )
                : '#ccc';
            }
            return '#ccc';
          })
          .attr('stroke-width', (d) => {
            return '3px';
          });

        /* NEEDS TESTING */
        elem.selectAll('path.sector').remove();
        elem.selectAll('g.icon').remove();
        const p = Math.PI * 2;
        d.sectors.length > 0 &&
          d.sectors.forEach((s, i) => {
            const sectors = d.sectors.length;
            const start = (i * p) / sectors;
            const end = ((i + 1) * p) / sectors;
            const sector = this.Sectors.find((a) => {
              return a.id === s;
            });
            elem
              .append('path')
              .attr('class', 'sector')
              .attr('d', arc({ startAngle: start, endAngle: end }))
              .attr('fill', function (d) {
                return sector.color;
              });
            const icon = elem
              .append('g')
              .attr('class', 'icon')
              .attr('fill', '#ffffff')
              .attr('fill-rule', 'evenodd')
              .attr(
                'transform',
                `translate(${centers[d.sectors.length - 1][i].x}, ${
                  centers[d.sectors.length - 1][i].y
                })`
              );
            this.addIcon(icon.node(), sector.svg);
          });

        elem.select('text').remove();

        const text = this.props.store.svgTexts.find((t) => {
          return t.id === d.id;
        });

        elem.node().appendChild(text.data.text);
      });

    item
      .enter()
      .append('g')
      .attr('class', 'node item')
      .on('click', (data, index, nodes) => {
        d3.event.stopPropagation();
        this.removeNode(data, nodes[index]);
      })
      .on('mouseenter', this.nodeMouseEnter)
      .on('mouseleave', this.nodeMouseLeave)
      .call(
        d3
          .drag()
          .on('start', this.dragstarted)
          .on('drag', this.dragged)
          .on('end', this.dragended)
      )
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem
          .append('circle')
          .attr('class', 'impact')
          .attr('r', 58)
          .attr('fill', '#FFF')
          .attr('stroke', (d) => {
            const i = d.impacts;
            const impact = this.Impacts.find((a) => {
              return i.includes(a.id);
            });
            return impact && impact.color ? impact.color : '#FFF';
          })
          .attr('stroke-width', (d) => {
            const i = d.impacts;
            const impact = this.Impacts.find((a) => {
              return i.includes(a.id);
            });
            return impact &&
              impact.color === '#ffffff' &&
              impact.style === 'solid'
              ? '0px'
              : '5px';
          })
          .attr('stroke-dasharray', (d) => {
            const i = d.impacts;
            const impact = this.Impacts.find((a) => {
              return i.includes(a.id);
            });
            return impact && impact.style === 'dashed' ? '7px' : '0px';
          });

        const p = Math.PI * 2;
        d.sectors.length > 0 &&
          d.sectors.forEach((s, i) => {
            const sectors = d.sectors.length;
            const start = (i * p) / sectors;
            const end = ((i + 1) * p) / sectors;
            const sector = this.Sectors.find((a) => {
              return a.id === s;
            });
            elem
              .append('path')
              .attr('class', 'sector')
              .attr('d', arc({ startAngle: start, endAngle: end }))
              .attr('fill', function (d) {
                return sector.color;
              });
            const icon = elem
              .append('g')
              .attr('class', 'icon')
              .attr('fill', '#ffffff')
              .attr('fill-rule', 'evenodd')
              .attr(
                'transform',
                `translate(${centers[d.sectors.length - 1][i].x}, ${
                  centers[d.sectors.length - 1][i].y
                })`
              );
            this.addIcon(icon.node(), sector.svg);
          });
        elem
          .append('circle')
          .attr('class', 'consequence')
          .attr('r', 38)
          .attr('fill', (d) => {
            const c = d.consequences;
            if (c.length > 0) {
              const consequence = this.Consequences.find((a) => {
                return c.includes(a.id);
              });
              return consequence.color ? consequence.color : '#FFF';
            }
            return '#FFF';
          })
          .attr('stroke', (d) => {
            const c = d.consequences;
            if (c.length > 0) {
              const consequence = this.Consequences.find((a) => {
                return c.includes(a.id);
              });
              return consequence.color
                ? '#' +
                    consequence.color
                      .replace(/^#/, '')
                      .replace(/../g, (color) =>
                        (
                          '0' +
                          Math.min(
                            255,
                            Math.max(0, parseInt(color, 16) - 50)
                          ).toString(16)
                        ).substr(-2)
                      )
                : '#ccc';
            }
            return '#ccc';
          })
          .attr('stroke-width', (d) => {
            return '3px';
          });

        const text = this.props.store.svgTexts.find((t) => {
          return t.id === d.id;
        });

        elem.node().appendChild(text.data.text);
      });

    item.exit().remove();
    item.order();

    return this.canvas.selectAll('g.item');
  };

  drawLinks = (links) => {
    let linesContainer = this.canvas.select('g.lines');
    if (linesContainer.empty()) {
      linesContainer = this.canvas.append('g').attr('class', 'lines');
    }

    let link = linesContainer
      .selectAll('line.link')
      .data(links, (d, i) => {
        return d.source + '-' + d.target;
      })
      .each((d, i, nodes) => {
        const elem = d3.select(nodes[i]);
        elem
          .attr(
            'stroke-dasharray',
            d.strokeDashArray ? d.strokeDashArray : '0,0'
          )
          .attr('stroke', d.stroke ? d.stroke : '#344F5B');
        if (false) {
          elem.attr('marker-start', d.marker ? 'url(#arrowhead)' : '');
        }
      });

    const line = link.enter().append('line').attr('class', 'link');

    line
      .attr('stroke-width', 2)
      .attr('stroke-linecap', 'round')
      .attr('stroke-dasharray', (d) => {
        return d.strokeDashArray ? d.strokeDashArray : '0,0';
      })
      .attr('stroke', (d) => {
        return d.stroke ? d.stroke : '#344F5B';
      });
    if (false) {
      line.attr('marker-start', (d) => {
        return d.marker ? 'url(#arrowhead)' : '';
      });
    }

    link.exit().remove();

    return this.canvas.selectAll('line.link');
  };

  zoomFit = (shouldAddSidebar) => {
    const bounds = this.canvas.node().getBBox();
    const parent = this.svg.node();
    const fullWidth = parent.clientWidth || parent.parentNode.clientWidth,
      fullHeight = parent.clientHeight || parent.parentNode.clientHeight;
    const width = bounds.width,
      height = bounds.height;
    const midX = bounds.x + width / 2,
      midY = bounds.y + height / 2;
    if (width === 0 || height === 0) return; // nothing to fit
    const scale = 0.85 / Math.max(width / fullWidth, height / fullHeight);
    const translate = [
      fullWidth / 2 - scale * midX,
      fullHeight / 2 - scale * midY,
    ];

    const transform = d3.zoomIdentity
      .translate(translate[0], translate[1])
      .scale(scale);

    this.svg
      .transition()
      .duration(250) // milliseconds
      .call(this.zoom.transform, transform);
  };

  nodeMouseEnter = (d) => {
    if (this.props.store.activeStep === 2) {
      const links = this.Links;

      let connectedLinks = links.filter(
        (l) => l.source.id === d.id || l.target.id === d.id
      );
      if (d.id.includes('effect-')) {
        let linkedNodes = connectedLinks
          .map((s) => s.source.id)
          .concat(connectedLinks.map((d) => d.target.id));
        this.selectedLinks = this.selectedLinks.concat(connectedLinks);
        this.selectedNodes = this.selectedNodes.concat(linkedNodes);

        this.svg
          .selectAll('.node')
          .attr('opacity', 0.2)
          .filter((n) => {
            return this.selectedNodes.includes(n.id);
          })
          .attr('opacity', 1);
        this.svg
          .selectAll('.link')
          .attr('opacity', 0.05)
          .filter((l) => {
            return this.selectedLinks.includes(l);
          })
          .attr('opacity', 1);
      } else {
        connectedLinks.forEach((cl) => {
          if (cl.source.id.includes('effect-')) {
            this.nodeMouseEnter(cl.source);
          }
          if (cl.target.id.includes('effect-')) {
            this.nodeMouseEnter(cl.target);
          }
        });
      }
    }
  };

  nodeMouseLeave = (d) => {
    if (this.props.store.activeStep < 3) {
      this.selectedNodes = [];
      this.selectedLinks = [];
      this.svg.selectAll('.node').attr('opacity', 1);
      this.svg.selectAll('.link').attr('opacity', 1);
    }
  };

  dragstarted = (d) => {
    if (this.props.store.activeStep === 2) {
      if (!d3.event.active) {
        this.dragSimulation
          .force(
            'collide',
            d3
              .forceCollide()
              .radius((d) => {
                let radius = 65;
                if (d.id.includes('trend')) {
                  radius = 62;
                } else if (d.id.includes('effect')) {
                  radius = 48;
                } else {
                  radius = 60;
                }
                return radius;
              })
              .iterations(10)
          )

          .alphaTarget(0.3)
          .restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    }
  };

  dragged = (d) => {
    if (this.props.store.activeStep === 2) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }
  };

  dragended = (d) => {
    if (this.props.store.activeStep === 2) {
      if (!d3.event.active) this.dragSimulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  };

  removeNode = (data, elem) => {
    const removed = [];
    if (this.props.store.activeStep === 2) {
      if (this.allowedToRemove.allowed) {
        elem.parentNode.removeChild(elem);
        removed.push(data.id);
        //remove all links related to elem
        let itemsAffected = [];
        d3.selectAll('line.link')
          .filter(function (link) {
            if (link.target === data || link.source === data) {
              itemsAffected.push(link.target.id);
              itemsAffected.push(link.source.id);
              return true;
            }
            return false;
          })
          .remove();
        itemsAffected = [...new Set(itemsAffected)];
        if (itemsAffected.length > 0) {
          d3.selectAll('line.link').each(function (link) {
            itemsAffected = itemsAffected.filter((e) => {
              if (e.includes('effect')) {
                return link.target.id !== e;
              } else {
                return link.source.id !== e && link.target.id !== e;
              }
            });
          });
          const links = [];

          d3.selectAll('g.item')
            .filter(function (item) {
              if (itemsAffected.includes(item.id)) {
                removed.push(item.id);
                return true;
              }
              return false;
            })
            .remove();

          d3.selectAll('g.effect')
            .filter(function (effect) {
              if (itemsAffected.includes(effect.id)) {
                removed.push(effect.id);
                links.push(effect.id);
                return true;
              }
              return false;
            })
            .remove();

          d3.selectAll('line.link')
            .filter(function (link) {
              return (
                links.includes(link.source.id) || links.includes(link.target.id)
              );
            })
            .remove();
        }
        this.allowedToRemove = {
          allowed: false,
        };
      } else {
        this.allowedToRemove = {
          allowed: true,
          elem: elem,
          data: data,
        };
      }
    }
    this.props.store.filteredData.items =
      this.props.store.filteredData.items.filter((item) => {
        return !removed.includes(item.id);
      });

    this.props.store.filteredData.effects =
      this.props.store.filteredData.effects.filter((effect) => {
        return !removed.includes(effect.id);
      });
  };

  ticked = () => {
    this.dTrends !== '' &&
      this.dTrends.attr('transform', function (d) {
        return `translate(${d.x}, ${d.y})`;
      });
    this.dEffects !== '' &&
      this.dEffects.attr('transform', function (d) {
        return `translate(${d.x}, ${d.y})`;
      });
    this.dItems !== '' &&
      this.dItems.attr('transform', (d) => {
        return `translate(${d.x}, ${d.y})`;
      });
    this.dLinks !== '' &&
      this.dLinks
        .attr('x1', function (d) {
          return d.source.x;
        })
        .attr('y1', function (d) {
          return d.source.y;
        })
        .attr('x2', function (d) {
          return d.target.x;
        })
        .attr('y2', function (d) {
          return d.target.y;
        });
  };

  wrap = (text, width, type) => {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = type === 'trend' ? 17 : type === 'effect' ? 13 : 10,
        y = parseFloat(text.attr('y')) || 0,
        tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y);

      width = typeof width === 'function' ? width.call(this) : width;

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          y = lineHeight + y;
          lineNumber++;
          tspan = text.append('tspan').attr('x', 0).attr('y', y).text(word);
          if (type === 'trend' && lineNumber === 1) {
            tspan.style('text-transform', 'uppercase');
            tspan.style('font-weight', 'bold');
            tspan.style('font-family', this.fontFamily);
          }
        }
      }
    });
  };

  addIcon = (icon, data) => {
    const dXML = new DOMParser();
    dXML.async = false;
    const sXML = "<svg xmlns='http://www.w3.org/2000/svg'>" + data + '</svg>';
    const svgDocElement = dXML.parseFromString(
      sXML,
      'text/xml'
    ).documentElement;

    while (icon.firstChild) {
      icon.removeChild(icon.firstChild);
    }
    let childNode = svgDocElement.firstChild;
    while (childNode) {
      icon.appendChild(icon.ownerDocument.importNode(childNode, true));
      childNode = childNode.nextSibling;
    }
  };

  addSidebar = () => {
    const box = document.getElementById('canvas');
    const transform = box.getAttribute('transform');
    box.removeAttribute('transform');
    const defaultOffset = 15;
    const defaultLineHeight = 30;
    const defaultWidth = 250;
    const canvasOffset = this.canvas.node().getBBox();
    const aside = this.canvas.append('g').attr('id', 'sidebar');
    const legend = aside.append('g');
    new SvgText({
      text: 'Legenda',
      element: legend.node(),
      maxWidth: defaultWidth,
      textOverflow: 'ellipsis',
      attrs: {
        fontFamily: this.fontFamily,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: 24,
        fill: '#333333',
      },
    });
    let bBox = defaultLineHeight + defaultOffset;
    const title = aside.append('g').attr('transform', `translate(0,${bBox})`);
    new SvgText({
      text: document.title,
      element: title.node(),
      maxWidth: defaultWidth - 10,
      textOverflow: 'ellipsis',
      attrs: {
        fontFamily: this.fontFamily,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 22,
        fill: '#333333',
      },
    });

    title
      .append('line')
      .attr('x1', 0)
      .attr('y1', bBox + 20)
      .attr('x2', 250)
      .attr('y2', bBox + 22)
      .attr('stroke', '#333333');

    bBox += defaultLineHeight * 2 + defaultOffset * 1.25;
    //trends
    const trends = this.props.store.initialData.trends;
    if (trends.length > 0) {
      const trend = aside.append('g').attr('transform', `translate(0,${bBox})`);
      new SvgText({
        text: 'Trends',
        element: trend.node(),
        maxWidth: defaultWidth,
        textOverflow: 'ellipsis',
        attrs: {
          fontFamily: this.fontFamily,
          fontSize: 18,
          lineHeight: 20,
          fill: '#333333',
        },
      });
      bBox += defaultLineHeight + defaultOffset / 3;
      const trendContainer = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);

      let dy = 0;
      trends.forEach((trend) => {
        const t = trendContainer.append('g');

        t.attr('transform', `translate(0,${dy})`);
        t.append('circle')
          .attr('fill', '#eeeeee')
          .attr('r', 15)
          .attr('cy', 15)
          .attr('cx', 15);
        const icon = t.append('g').attr('transform', 'translate(5, 5)');
        this.addIcon(
          icon.node(),
          trend.svg
            .replace('width="50"', 'width="20"')
            .replace('height="50"', 'height="20"')
        );
        new SvgText({
          text: trend.title,
          element: t.node(),
          maxWidth: defaultWidth,
          textOverflow: 'ellipsis',
          y: 5,
          x: 40,
          attrs: {
            fontFamily: this.fontFamily,
            fontSize: 15,
            lineHeight: 18,
            fill: '#333333',
          },
        });
        dy += defaultLineHeight + defaultOffset / 3;
      });
      bBox +=
        trends.length * (defaultLineHeight + defaultOffset / 3) +
        defaultOffset * 1.5;
    }

    //sectors
    const sectors = this.props.store.initialData.sectors;
    if (sectors.length > 0) {
      const sector = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);
      new SvgText({
        text: 'Sectoren',
        element: sector.node(),
        maxWidth: defaultWidth,
        textOverflow: 'ellipsis',
        attrs: {
          fontFamily: this.fontFamily,
          fontSize: 18,
          lineHeight: 20,
          fill: '#333333',
        },
      });
      bBox += defaultLineHeight + defaultOffset / 3;
      const sectorContainer = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);

      let dy = 0;
      sectors.forEach((sector) => {
        const t = sectorContainer.append('g');

        t.attr('transform', `translate(0,${dy})`);
        t.append('circle')
          .attr('fill', sector.color)
          .attr('r', 15)
          .attr('cy', 15)
          .attr('cx', 15);
        const icon = t.append('g').attr('transform', 'translate(8, 8)');
        this.addIcon(
          icon.node(),
          sector.svg
            .replace('width="13"', 'width="14"')
            .replace('height="13"', 'height="14"')
        );

        new SvgText({
          text: sector.title,
          element: t.node(),
          maxWidth: defaultWidth,
          textOverflow: 'ellipsis',
          y: 5,
          x: 40,
          attrs: {
            fontFamily: this.fontFamily,
            fontSize: 15,
            lineHeight: 18,
            fill: '#333333',
          },
        });
        dy += defaultLineHeight + defaultOffset / 3;
      });
      bBox +=
        sectors.length * (defaultLineHeight + defaultOffset / 3) +
        defaultOffset * 1.5;
    }

    //Impact
    const impacts = this.props.store.initialData.impacts;
    if (impacts.length > 0) {
      const impact = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);
      new SvgText({
        text: 'Impact',
        element: impact.node(),
        maxWidth: defaultWidth,
        textOverflow: 'ellipsis',
        attrs: {
          fontFamily: this.fontFamily,
          fontSize: 18,
          lineHeight: 20,
          fill: '#333333',
        },
      });
      bBox += defaultLineHeight + defaultOffset / 3;
      const impactContainer = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);

      let dy = 0;
      let impactsLength = impacts.length;
      impacts.forEach((impact) => {
        if (impact.color === '#ffffff' && impact.style === 'solid') {
          impactsLength--;
          return;
        }
        const t = impactContainer.append('g');

        t.attr('transform', `translate(0,${dy})`);
        t.append('circle')
          .attr('fill', '#FFFFFF')
          .attr('stroke', impact.color)
          .attr('stroke-width', 1)
          .attr(
            'stroke-dasharray',
            impact && impact.style === 'dashed' ? '3px' : '0px'
          )
          .attr('r', 15)
          .attr('cy', 15)
          .attr('cx', 15);

        new SvgText({
          text: impact.title,
          element: t.node(),
          maxWidth: defaultWidth,
          textOverflow: 'ellipsis',
          y: 5,
          x: 40,
          attrs: {
            fontFamily: this.fontFamily,
            fontSize: 15,
            lineHeight: 18,
            fill: '#333333',
          },
        });
        dy += defaultLineHeight + defaultOffset / 3;
      });
      bBox +=
        impactsLength * (defaultLineHeight + defaultOffset / 3) +
        defaultOffset * 1.5;
    }

    //Consequences
    const consequences = this.props.store.initialData.consequences;
    if (consequences.length > 0) {
      const consequence = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);
      new SvgText({
        text: 'Aard gevolgen',
        element: consequence.node(),
        maxWidth: defaultWidth,
        textOverflow: 'ellipsis',
        attrs: {
          fontFamily: this.fontFamily,
          fontSize: 18,
          lineHeight: 20,
          fill: '#333333',
        },
      });
      bBox += defaultLineHeight + defaultOffset / 3;
      const consequenceContainer = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);

      let dy = 0;
      consequences.forEach((consequence) => {
        const t = consequenceContainer.append('g');

        t.attr('transform', `translate(0,${dy})`);
        t.append('circle')
          .attr('fill', consequence.color)
          .attr('r', 15)
          .attr('cy', 15)
          .attr('cx', 15);

        new SvgText({
          text: consequence.title,
          element: t.node(),
          maxWidth: defaultWidth,
          textOverflow: 'ellipsis',
          y: 5,
          x: 40,
          attrs: {
            fontFamily: this.fontFamily,
            fontSize: 15,
            lineHeight: 18,
            fill: '#333333',
          },
        });
        dy += defaultLineHeight + defaultOffset / 3;
      });

      bBox +=
        consequences.length * (defaultLineHeight + defaultOffset / 3) +
        defaultOffset * 1.5;
    }

    if (
      this.props.store.texts &&
      this.props.store.texts.export &&
      this.props.store.texts.export.disclaimer
    ) {
      const disclaimer = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);
      new SvgText({
        text: this.props.store.texts.export.disclaimer,
        element: disclaimer.node(),
        width: defaultWidth,
        textOverflow: 'ellipsis',
        y: 0,
        x: 0,
        attrs: {
          fontFamily: this.fontFamily,
          fontStyle: 'italic',
          fontSize: 11,
          lineHeight: 13,
          fill: '#333333',
        },
      });
      bBox += defaultLineHeight * 2 + defaultOffset * 1.5;
    }

    if (
      this.props.store.texts &&
      this.props.store.texts.export &&
      (this.props.store.texts.export.number ||
        this.props.store.texts.export.date)
    ) {
      const version = aside
        .append('g')
        .attr('transform', `translate(0,${bBox})`);
      let string = '';
      if (
        this.props.store.texts.export.number &&
        this.props.store.texts.export.date
      ) {
        string = `${this.props.store.texts.export.number} - ${this.props.store.texts.export.date}`;
      } else if (this.props.store.texts.export.number) {
        string = this.props.store.texts.export.number;
      } else {
        string = this.props.store.texts.export.date;
      }
      new SvgText({
        text: string,
        element: version.node(),
        maxWidth: defaultWidth,
        textOverflow: 'ellipsis',
        y: 0,
        x: 0,
        attrs: {
          fontFamily: this.fontFamily,
          fontSize: 11,
          lineHeight: 13,
          fill: '#333333',
        },
      });
    }
    const asideOffset = aside.node().getBBox();
    let posY = Math.abs(canvasOffset.y);
    if (asideOffset.height > canvasOffset.height) {
      posY =
        -Math.abs(canvasOffset.y) -
        (canvasOffset.height - asideOffset.height) / 2;
    }
    const canvasSizes = canvasOffset.x + canvasOffset.width + 100;
    aside.attr(
      'transform',
      `translate(${Math.abs(canvasSizes)}, ${-Math.abs(posY)})`
    );
    if (transform) {
      box.setAttribute('transform', transform);
    }
    setTimeout(() => {
      this.zoomFit(false);
    }, 10);
  };

  render() {
    const items = this.props.store.filteredData.items.length;
    const filters = this.props.store.activeFilters;
    const texts = this.props.store.texts;
    const step = this.props.store.activeStep;
    return (
      <main
        ref='main'
        className={!this.props.store.sidebarVisible ? 'fullscreen' : undefined}
        data-step={step}
      >
        <svg id='svg' />
        {items === 0 && (
          <div className='bzk-canvas__noresults'>
            <div className='bzk-canvas__noresults--inner'>
              <h2>
                {texts ? texts.step_1.no_results_title : `Geen resultaten`}
              </h2>
              <p>
                {texts ? texts.step_1.no_results_text : `Pas uw filters aan`}
              </p>
            </div>
          </div>
        )}
        {filters !== 0 && (
          <div className='bzk-canvas__controls'>
            <button
              title='Omhoog'
              className='bzk-canvas__control bzk-canvas__control--arrow bzk-canvas__control--up'
              onClick={() => {
                this.moveCanvas('down');
              }}
            >
              Omhoog
            </button>
            <button
              title='Naar rechts'
              className='bzk-canvas__control bzk-canvas__control--arrow bzk-canvas__control--right'
              onClick={() => {
                this.moveCanvas('left');
              }}
            >
              Naar rechts
            </button>
            <button
              title='Omlaag'
              className='bzk-canvas__control bzk-canvas__control--arrow bzk-canvas__control--down'
              onClick={() => {
                this.moveCanvas('up');
              }}
            >
              Omlaag
            </button>
            <button
              title='Naar links'
              className='bzk-canvas__control bzk-canvas__control--arrow bzk-canvas__control--left'
              onClick={() => {
                this.moveCanvas('right');
              }}
            >
              Naar links
            </button>
            <button
              disabled={this.zoomInDisabled}
              title='Inzoomen'
              className='bzk-canvas__control bzk-canvas__control--plus'
              onClick={() => {
                this.zoomCanvas(true);
              }}
            >
              +
            </button>
            <button
              disabled={this.zoomOutDisabled}
              title='Uitzoomen'
              className='bzk-canvas__control bzk-canvas__control--min'
              onClick={() => {
                this.zoomCanvas(false);
              }}
            >
              -
            </button>
          </div>
        )}
        {this.allowedToRemove.allowed && (
          <div className='bzk-canvas__noresults'>
            <div className='bzk-canvas__noresults--inner'>
              <h2>{texts ? texts.step_2.delete_title : `Verwijderen?`}</h2>
              <p>
                {texts
                  ? texts.step_2.delete_text
                  : `Je staat op het punt om een bol (en eventuele
								connecties) te verwijderen. Het is hierna niet
								meer mogelijk om deze bol terug te halen. Weet
								je zeker dat je door wilt gaan?`}
              </p>
              <button
                className='bzk-notice__mobile-btn'
                onClick={() => {
                  this.allowedToRemove.allowed = false;
                }}
              >
                Annuleren
              </button>
              <button
                className='bzk-notice__mobile-btn'
                onClick={() => {
                  this.removeNode(
                    this.allowedToRemove.data,
                    this.allowedToRemove.elem
                  );
                }}
              >
                Doorgaan
              </button>
            </div>
          </div>
        )}

        <Modals />
        <Pages />
      </main>
    );
  }
}
decorate(Canvas, {
  zoomInDisabled: observable,
  zoomOutDisabled: observable,
  allowedToRemove: observable,
});
export default inject('store')(observer(Canvas));
