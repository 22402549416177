import React, { Component } from 'react';
import { observable, decorate } from 'mobx';
import { observer, inject } from 'mobx-react';

class Modals extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  componentDidMount() {
    if (this.myRef && this.myRef.current) {
      this.myRef.current.focus();
    }
  }
  componentDidUpdate() {
    if (
      this.myRef.current &&
      ['privacy', 'a11y', 'cookies'].includes(this.props.store.notice.type)
    ) {
      this.myRef.current.focus();
    }
  }
  mobileVisible = true;
  render() {
    const texts = this.props.store.texts;
    const cookies_not_set =
      texts.cookies_privacy && texts.cookies_privacy.cookies_not_set
        ? texts.cookies_privacy.cookies_not_set
        : 'Op dit moment heb je nog geen keuze gemaakt';
    const cookies_accepted =
      texts.cookies_privacy && texts.cookies_privacy.cookies_accepted
        ? texts.cookies_privacy.cookies_accepted
        : 'Op dit moment heb je cookies geaccepteerd';
    const cookies_declined =
      texts.cookies_privacy && texts.cookies_privacy.cookies_declined
        ? texts.cookies_privacy.cookies_declined
        : 'Op dit moment heb je cookies geweigerd';

    const cookieSetting =
      typeof this.props.store.cookiesAllowed === 'undefined'
        ? cookies_not_set
        : this.props.store.cookiesAllowed === false
        ? cookies_declined
        : cookies_accepted;
    return (
      <div tabIndex='0' ref={this.myRef}>
        {!this.props.store.notice.display &&
          this.props.store.activeFilters === 0 && (
            <div className='bzk-canvas__noresults'>
              <div className='bzk-canvas__noresults--inner'>
                <h2 tabIndex='0'>
                  {texts ? texts.step_1.intro_title : `Maak een selectie`}
                </h2>
                <div
                  tabIndex='0'
                  className='bzk-notice__content'
                  dangerouslySetInnerHTML={{
                    __html: texts
                      ? texts.step_1.intro_text
                      : `<p>Kies in het linker menu welke filters voor jou
								van toepassing zijn. De illustratie zal zich bij
								elke wijziging direct aanpassen.</p>`,
                  }}
                />
                {typeof this.props.store.cookiesAllowed === 'undefined' && (
                  <div className='c-notice__cookie-msg' tabIndex='0'>
                    <small>
                      Deze website gebruikt cookies om de gebruikerservaring te
                      verbeteren.
                      <br />
                      <button
                        className='bzk-panel__button bzk-panel__button--secondary'
                        onClick={() => {
                          this.props.store.trackPageview({
                            modal: true,
                            url: '/cookie-instellingen',
                          });
                          this.props.store.notice = {
                            display: true,
                            type: 'cookies',
                            title: 'Voorkeuren wijzigen',
                            html: cookieSetting,
                          };
                        }}
                      >
                        Voorkeur wijzigen
                      </button>
                    </small>
                  </div>
                )}
              </div>
            </div>
          )}
        {this.props.store.notice.display && (
          <div className='bzk-canvas__noresults'>
            <div className='bzk-canvas__noresults--inner'>
              <h2 tabIndex='0'>{this.props.store.notice.title}</h2>
              {this.props.store.notice.description && (
                <p tabIndex='0'>{this.props.store.notice.description}</p>
              )}

              {!this.props.store.notice.description &&
                this.props.store.notice.html && (
                  <div
                    tabIndex='0'
                    className='bzk-notice__content'
                    dangerouslySetInnerHTML={{
                      __html: this.props.store.notice.html,
                    }}
                  />
                )}

              {this.props.store.notice.type === 'cookies' && (
                <div className='bzk-notice__buttons'>
                  <button
                    tabIndex='0'
                    className='bzk-panel__button bzk-panel__button--secondary'
                    onClick={() => {
                      if (typeof this.cookiesAllowed === 'undefined') {
                        this.props.store.enableTracking();
                      }
                      if (
                        this.props.store.activeElem &&
                        this.props.store.activeElem.current
                      ) {
                        this.props.store.activeElem.current.focus();
                        this.props.store.activeElem = null;
                      }
                      this.props.store.cookiesAllowed = true;
                      this.props.store.notice = {
                        display: false,
                        title: '',
                        description: '',
                        html: '',
                        type: '',
                      };
                    }}
                  >
                    Cookies toestaan
                  </button>
                  <button
                    tabIndex='0'
                    className='bzk-panel__button bzk-panel__button--secondary'
                    onClick={() => {
                      if (
                        this.props.store.activeElem &&
                        this.props.store.activeElem.current
                      ) {
                        this.props.store.activeElem.current.focus();
                        this.props.store.activeElem = null;
                      }
                      this.props.store.cookiesAllowed = false;
                      this.props.store.notice = {
                        display: false,
                        title: '',
                        description: '',
                        html: '',
                        type: '',
                      };
                    }}
                  >
                    Cookies weigeren
                  </button>
                </div>
              )}

              <button
                className='bzk-notice__mobile-btn'
                onKeyDown={(e) => {
                  if (
                    (e.keyCode === 13 ||
                      e.key === 'Enter' ||
                      e.key === 'enter') &&
                    this.props.store.activeElem &&
                    this.props.store.activeElem.current
                  ) {
                    this.props.store.activeElem.current.focus();
                    this.props.store.activeElem = null;
                  }
                }}
                onClick={() => {
                  this.props.store.notice = {
                    display: false,
                    title: '',
                    description: '',
                    html: '',
                    type: '',
                  };
                }}
              >
                Sluiten{' '}
                <small>
                  <small>(esc)</small>
                </small>
              </button>
            </div>
          </div>
        )}
        {!this.props.store.notice.display && this.props.store.exporting && (
          <div className='bzk-canvas__noresults'>
            <div className='bzk-canvas__noresults--inner'>
              <h2>{texts ? texts.export.title : 'Export wordt gegenereerd'}</h2>
              <p>
                {texts
                  ? texts.export.content
                  : 'Laat je tabblad open tot de download is voltooid. Deze melding verdwijnt zodra de download voltooid is. Deze is vervolgens in je download folder te vinden.'}
              </p>
            </div>
          </div>
        )}
        {this.mobileVisible && (
          <div className='bzk-notice__mobile'>
            <div className='bzk-canvas__noresults--inner'>
              <h2>
                {texts ? texts.mobile.title : `Alleen geschikt voor desktop`}
              </h2>
              <p>
                {texts
                  ? texts.mobile.description
                  : `Het lijkt er op dat je op een mobiel device zit, 
							of dat je deze tool op een klein scherm bekijkt. 
							Helaas kunnen wij geen volledige werking garanderen.`}
              </p>

              <button
                className='bzk-notice__mobile-btn'
                onClick={() => {
                  this.mobileVisible = false;
                }}
              >
                Ik begrijp het, toch doorgaan
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
decorate(Modals, {
  mobileVisible: observable,
});
export default inject('store')(observer(Modals));
