import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import Aside from './components/aside';
import Canvas from './components/canvas';
import Api from './services/api';
import Data from './data/data';

const App = observer(
  class App extends Component {
    componentDidMount() {
      Data.init(Api);
    }
    render() {
      return (
        <Provider store={Data}>
          <div className='rootInner'>
            {Data.dataLoaded ? (
              <div className='bzk'>
                <Canvas />
                <Aside />
              </div>
            ) : (
              <div className='bzk-loader'>
                <i className='bzk-options__spinner' />
                <h2 className='bzk-loader__title'>LADEN</h2>
              </div>
            )}
            <svg id='texts' />
          </div>
        </Provider>
      );
    }
  }
);

export default App;
