import React, { Component } from "react";
import { observable, decorate } from "mobx";
import { observer, inject } from "mobx-react";
import pngExport from "save-svg-as-png";
import { jsPDF } from "jspdf";
const jsonexport = require("jsonexport/dist");
const canvg = require("canvg");
const { detect } = require("detect-browser");
const browser = detect();

class Aside extends Component {
	exportPNG = false;
	exportSVG = false;
	exportXLS = false;
	exportPDF = false;
	pngOutput = false;

	data = {
		trends: null,
		effects: null,
		sectors: null,
		impacts: null,
		consequences: null,
	};
	componentDidMount() {
		this.data.trends = this.props.store.initialData.trends;
		this.data.effects = this.props.store.initialData.effects;
		this.data.sectors = this.props.store.initialData.sectors;
		this.data.impacts = this.props.store.initialData.impacts;
		this.data.consequences = this.props.store.initialData.consequences;
	}

	toggleSidebar = () => {
		this.props.store.sidebarVisible = !this.props.store.sidebarVisible;
	};

	toggleNotice = (type) => {
		this.props.store.trackPageview({
			modal: true,
			url: `/help/${type}`,
		});
		if (
			this.props.store.notice.title ===
			this.props.store.texts.notices[type].title
		) {
			this.props.store.notice = {
				display: false,
				title: "",
				description: "",
				html: "",
				type: "",
			};
		} else {
			this.props.store.notice = {
				display: true,
				title: this.props.store.texts.notices[type].title,
				description: this.props.store.texts.notices[type].description,
			};
		}
	};

	nextStep = () => {
		if (this.props.store.activeStep === 1) {
			var child = document.getElementById("texts");
			child.parentNode.removeChild(child);
		}
		this.props.store.activeStep++;
		this.props.store.trackEvent({
			category: "Navigeren",
			action: "Volgende stap aangeklikt",
			label: `Stap ${this.props.store.activeStep}`,
		});
	};

	prevStep = () => {
		this.props.store.activeStep--;
		this.props.store.trackEvent({
			category: "Navigeren",
			action: "Vorige stap aangeklikt",
			label: `Stap ${this.props.store.activeStep}`,
		});
	};

	export = (type) => {
		this.props.store.exporting = true;
		const svg = document.getElementById("svg");
		if (svg.firstChild.tagName.toLowerCase() === "style") {
			svg.removeChild(svg.firstChild);
		}
		const clone = svg.cloneNode(true);
		clone.firstChild.removeAttribute("transform");
		const box = svg.firstChild;
		const transform = box.getAttribute("transform");
		box.removeAttribute("transform");
		const dimensions = box.getBBox();
		if (transform) {
			box.setAttribute("transform", transform);
		}
		const options = {
			backgroundColor: "white",
			left: dimensions.x - 40,
			top: dimensions.y - 40,
			width: dimensions.width + 80,
			height: dimensions.height + 80,
			encoderOptions: 0.8,
			ignoreMouse: true,
			ignoreAnimation: true,
			canvg: browser.name === "ie" ? canvg : false,
		};
		if (type === "png") {
			this.exportPNG = "prepare";
			options.scale = 1.25;
			const filename = `${document.title}.png`;
			pngExport.saveSvgAsPng(clone, filename, options);

			if (this.pngOutput === false) {
				pngExport.svgAsPngUri(clone, options, (uri) => {
					this.exportPNG = "done";
					this.pngOutput = uri;
					this.props.store.exporting = false;
				});
			} else {
				this.exportPNG = "done";
				this.props.store.exporting = false;
			}
		} else if (type === "svg") {
			this.exportSVG = "prepare";
			const filename = `${document.title}.svg`;
			pngExport.saveSvg(clone, filename, options);
			pngExport.svgAsDataUri(clone, options, (uri) => {
				this.exportSVG = "done";
				this.props.store.exporting = false;
			});
		} else if (type === "pdf") {
			this.exportPDF = "prepare";
			options.scale = 1.25;
			const filename = `${document.title}.pdf`;
			const doc = new jsPDF({
				orientation:
					dimensions.width > dimensions.height ? "landscape" : "portrait",
				unit: "px",
				format: [(dimensions.width + 80) * 1.3, (dimensions.height + 80) * 1.3],
				compress: true,
			});
			doc.setLanguage("nl-NL");
			if (this.pngOutput === false) {
				pngExport.svgAsPngUri(clone, options, (uri) => {
					this.pngOutput = uri;
					const result = `<img src="${uri}" alt="" width="${
						dimensions.width + 40
					}" height="${dimensions.height + 40}"/>`;
					const margins = {
						top: 10,
						bottom: 10,
						left: 10,
						width: dimensions.width + 40,
					};
					doc.html(result, {
						callback: (doc) => {
							this.exportPDF = "done";
							this.props.store.exporting = false;
							doc.setProperties({
								language: "nl-NL",
							});
							doc.setLanguage("nl-NL");
							doc.save(filename);
						},
						x: margins.left,
						y: margins.top,
					});
				});
			} else {
				const result = `<img src="${this.pngOutput}" alt="" width="${
					dimensions.width + 40
				}" height="${dimensions.height + 40}"/>`;
				const margins = {
					top: 10,
					bottom: 10,
					left: 10,
					width: dimensions.width + 40,
				};
				doc.html(result, {
					callback: (doc) => {
						this.exportPDF = "done";
						this.props.store.exporting = false;
						doc.setProperties({
							language: "nl-NL",
						});
						doc.setLanguage("nl-NL");
						doc.save(filename);
					},
					x: margins.left,
					y: margins.top,
				});
			}
		} else {
			alert("Dit formaat wordt niet ondersteund!");
		}
	};

	exportAsXLS = () => {
		const items = this.props.store.filteredData.items;
		const data = [];
		this.exportXLS = "prepare";
		items.forEach((item, index) => {
			let trends = item.trends.map((trend) => {
				return this.props.store.getItemByID(trend, "trends");
			});
			trends = [].concat.apply([], trends).map((trend) => {
				return trend.title;
			});
			let effects = item.effects.map((effect) => {
				return this.props.store.getItemByID(effect, "effects");
			});
			effects = [].concat.apply([], effects).map((effect) => {
				return effect.title;
			});
			let sectors = item.sectors.map((sector) => {
				return this.props.store.getItemByID(sector, "sectors");
			});
			sectors = [].concat.apply([], sectors).map((sector) => {
				return sector.title;
			});
			let impacts = item.impacts.map((impact) => {
				return this.props.store.getItemByID(impact, "impacts");
			});
			impacts = [].concat.apply([], impacts).map((impact) => {
				return impact.title;
			});
			let consequences = item.consequences.map((consequence) => {
				return this.props.store.getItemByID(consequence, "consequences");
			});
			consequences = [].concat.apply([], consequences).map((consequence) => {
				return consequence.title;
			});
			data.push({
				Trends: trends.join(", "),
				Effecten: effects.join(", "),
				Sectoren: sectors.join(", "),
				Impact: impacts.join(", "),
				"Aard gevolgen": consequences.join(", "),
				Gevolg: item.title,
			});
		});
		jsonexport(data, { rowDelimiter: ";" }, (err, csv) => {
			if (err) return alert("Er ging iets fout met de export :(");
			const filename = `${document.title}.csv`;
			const blob = new Blob([csv], { type: "text/csv" });
			if (window.navigator.msSaveBlob) {
				window.navigator.msSaveOrOpenBlob(blob, filename);
				this.exportXLS = "done";
			} else {
				const a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob, {
					type: "text/plain",
				});
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				this.exportXLS = "done";
			}
		});
	};

	toggleButton = (category, id) => {
		if (this.props.store.activeStep === 1) {
			this.refs[`button_${id}`].classList.toggle("active");
			if (category === "single-click-options") {
				this.props.store.initialData[category]
					.filter((item) => {
						return item.id !== id;
					})
					.forEach((item) => {
						this.refs[`button_${item.id}`].classList.remove("active");
					});
				this.props.store.setSearchParams(category, id);
			} else {
				this.props.store.updateSearchParams(category, id);
			}

			if (this.props.store.activeFilters > 0) {
				this.props.store.filteringActive = true;
			} else {
				this.props.store.filteringActive = false;
			}
		}
	};

	renderButton = (item, index, category) => {
		if (item.style === "solid" && item.color === "#ffffff") {
			return "";
		} else {
			return (
				<li className='bzk-panel__item' key={index}>
					<button
						ref={`button_${item.id}`}
						className={`bzk-panel__button bzk-panel__button--${category} ${
							!item.color && !item.svg ? "bzk-panel__button--no-img" : ""
						}`}
						data-id={item.id}
						onClick={() => this.toggleButton(category, item.id)}
					>
						{(item.color || item.svg) && !item.style && (
							<div
								style={{
									backgroundColor: item.color ? item.color : "#eee",
								}}
								className='bzk-panel__icon-holder'
							>
								{item.svg && (
									<div
										className='bzk-panel__icon'
										dangerouslySetInnerHTML={{
											__html: item.svg,
										}}
									/>
								)}
							</div>
						)}
						{item.style && (
							<div
								style={{
									borderColor: item.color ? item.color : "#fff",
									borderStyle: item.style ? item.style : "solid",
								}}
								className='bzk-panel__icon-holder'
							/>
						)}
						{item.title}
					</button>
				</li>
			);
		}
	};
	render() {
		const texts = this.props.store.texts;
		return (
			<aside
				className={`bzk-panel ${
					!this.props.store.sidebarVisible ? "closed" : ""
				} `}
				ref='sidebar'
				data-active-step={this.props.store.activeStep}
			>
				<button
					className='bzk-panel__close'
					onClick={() => this.toggleSidebar()}
				/>
				<ul className='bzk-options'>
					<li
						className={
							this.props.store.activeStep === 1
								? "bzk-options__active"
								: "bzk-options__inactive"
						}
					>
						<h4 className='bzk-options__title'>
							<span
								className={this.props.store.activeStep > 1 ? "checked" : ""}
							>
								1
							</span>{" "}
							{texts ? texts.step_1.title : "Filteren"}
							{this.props.store.filteringActive &&
								this.props.store.activeStep === 1 && (
									<i className='bzk-options__spinner' />
								)}
							{this.props.store.activeFilters > 0 &&
								this.props.store.activeStep === 1 && (
									<sup className={"bzk-panel__title--count"}>
										({this.props.store.filteredData.items.length} /{" "}
										{this.props.store.initialData.items.length})
									</sup>
								)}
						</h4>
						<div className='bzk-panel__content'>
							{this.data.trends && (
								<ul className='bzk-panel__list'>
									<li className='bzk-panel__title'>
										Klimaattrends
										<button
											title='Help'
											onClick={() => this.toggleNotice("trends")}
										>
											?
										</button>
									</li>
									{this.data.trends.map((item, index) => {
										return this.renderButton(item, index, "trends");
									})}
								</ul>
							)}
							{this.data.sectors && (
								<ul className='bzk-panel__list'>
									<li className='bzk-panel__title'>
										Sectoren
										<button
											title='Help'
											onClick={() => this.toggleNotice("sectors")}
										>
											?
										</button>
									</li>
									{this.data.sectors.map((item, index) => {
										return this.renderButton(item, index, "sectors");
									})}
								</ul>
							)}
							{this.data.impacts && (
								<ul className='bzk-panel__list'>
									<li className='bzk-panel__title'>
										Impact
										<button
											title='Help'
											onClick={() => this.toggleNotice("impacts")}
										>
											?
										</button>
									</li>
									{this.data.impacts.map((item, index) => {
										return this.renderButton(item, index, "impacts");
									})}
								</ul>
							)}
							{this.data.consequences && (
								<ul className='bzk-panel__list'>
									<li className='bzk-panel__title'>
										Aard gevolg
										<button
											title='Help'
											onClick={() => this.toggleNotice("consequences")}
										>
											?
										</button>
									</li>
									{this.data.consequences.map((item, index) => {
										return this.renderButton(item, index, "consequences");
									})}
								</ul>
							)}
						</div>
					</li>
					<li
						className={
							this.props.store.activeStep === 2
								? "bzk-options__active"
								: "bzk-options__inactive"
						}
					>
						<h4 className='bzk-options__title'>
							<span
								className={this.props.store.activeStep > 2 ? "checked" : ""}
							>
								2
							</span>{" "}
							{texts ? texts.step_2.title : "Filteren"}
						</h4>
						{this.props.store.activeFilters > 0 && (
							<div className='bzk-panel__footer'>
								{this.props.store.activeStep === 1 && (
									<div>
										<p>
											{texts
												? texts.step_1.next_step_text
												: `Klaar met filteren? Bij de volgende stap
										kan je de positie van de bollen
										optimaliseren.`}
										</p>
										<button
											onClick={() => this.nextStep()}
											className='bzk-panel__next'
										>
											Door naar stap {this.props.store.activeStep + 1}
										</button>
									</div>
								)}
								{this.props.store.activeStep === 2 && (
									<div>
										<p>
											{texts
												? texts.step_2.explanation_text
												: `Sleep de bollen in de gewenste positie.
										Met behulp van scrollen kan je in en
										uitzoomen.`}
										</p>
										<button
											onClick={() => this.prevStep()}
											style={{ display: "none" }}
										>
											Terug naar stap {this.props.store.activeStep - 1}
										</button>
									</div>
								)}
							</div>
						)}
					</li>
					<li
						className={
							this.props.store.activeStep === 3
								? "bzk-options__active"
								: "bzk-options__inactive"
						}
					>
						<h4 className='bzk-options__title'>
							<span>3</span> {texts ? texts.step_3.title : "Filteren"}
							{this.props.store.exporting &&
								this.props.store.activeStep === 3 && (
									<i className='bzk-options__spinner' />
								)}
						</h4>
						<div className='bzk-panel__footer bzk-panel__footer--mb'>
							{this.props.store.activeStep === 2 && (
								<div>
									<p>
										{texts
											? texts.step_2.next_step_text
											: `Klaar met positioneren? Bij de volgende
										stap kan je de illustratie exporteren
										naar verschillende formaten.`}
									</p>
									<button
										onClick={() => this.nextStep()}
										className='bzk-panel__next'
									>
										Door naar stap {this.props.store.activeStep + 1}
									</button>
								</div>
							)}
							{this.props.store.activeStep === 3 && (
								<div>
									<p>
										{texts
											? texts.step_3.explanation_text
											: `Gebruik de onderstaande opties om de
										illustratie in het gewenste formaat te
										exporteren.`}
									</p>
									<button
										onClick={() => this.export("png")}
										className='bzk-panel__next'
										disabled={this.exportPNG}
									>
										{this.exportPNG === "prepare"
											? "Download voorbereiden"
											: this.exportPNG === "done"
											? "Download voltooid"
											: "Exporteer als PNG"}
									</button>
									<button
										onClick={() => this.export("svg")}
										className='bzk-panel__next'
										disabled={this.exportSVG}
									>
										{this.exportSVG === "prepare"
											? "Download voorbereiden"
											: this.exportSVG === "done"
											? "Download voltooid"
											: "Exporteer als SVG"}
									</button>
									<button
										onClick={() => this.exportAsXLS()}
										className='bzk-panel__next'
										disabled={this.exportXLS}
									>
										{this.exportXLS === "prepare"
											? "Download voorbereiden"
											: this.exportXLS === "done"
											? "Download voltooid"
											: "Exporteer als XLS"}
									</button>
									{browser.name !== "ie" && (
										<button
											onClick={() => this.export("pdf")}
											className='bzk-panel__next'
											disabled={this.exportPDF}
										>
											{this.exportPDF === "prepare"
												? "Download voorbereiden"
												: this.exportPDF === "done"
												? "Download voltooid"
												: "Exporteer als PDF"}
										</button>
									)}
									<button
										onClick={() => this.prevStep()}
										style={{ display: "none" }}
									>
										Terug naar stap {this.props.store.activeStep - 1}
									</button>

									<a
										href='https://ruimtelijkeadaptatie.nl/nas/adaptatietool/'
										className='bzk-panel__next bzk-panel__link'
									>
										Afsluiten
									</a>
								</div>
							)}
						</div>
					</li>
				</ul>
			</aside>
		);
	}
}
decorate(Aside, {
	exportPNG: observable,
	exportSVG: observable,
	exportXLS: observable,
	exportPDF: observable,
	pngOutput: observable,
	data: observable,
});
export default inject("store")(observer(Aside));
